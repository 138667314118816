<template>
    <!-- Unterlagen per File-Upload oder Foto (Handy) -->
    <div class="section index-section" key="upload">
        <div class="section-title">
            <h3 class="mb-2">3. Lade Deine Unterlagen hoch</h3>
            <p class="small">Um Deinen Fall optimal beurteilen zu können benötigen wir den Anhörungsbogen oder Bußgeldbescheid, den Du von der Behörde bereits erhalten hast.</p>
        </div>
        <div class="form-container">
            <div class="input-file">
                <file-pond
                    :class="{ 'has-files': showSubmit }"
                    ref="pond"
                    name="file-1"
                    id="file-1"
                    allow-multiple="true"
                    max-files="5"
                    max-file-size="15MB"
                    label-max-file-size-exceeded="Datei zu groß"
                    label-max-file-size="Max. {filesize}"
                    accepted-file-types="image/jpeg, image/png, image/gif, application/pdf"
                    label-file-type-not-allowed="Ungültiges Format"
                    file-validate-type-labe-expected-types="{allButLastType} oder {lastType}"
                    file-validate-type-label-expected-types-map="{ 'image/jpeg': '.jpg', 'image/png': '.png', 'image/gif': '.gif', 'application/pdf': '.pdf' }"
                    label-idle="<p><u>Hier klicken</u> um Unterlagen auszuwählen / fotografieren</p>"
                    label-button-remove-item="Löschen"
                    label-file-processing-complete="Hochgeladen"
                    label-tap-to-cancel=""
                    label-tap-to-undo=""
                    label-file-processing="Hochladen"
                    allow-image-preview="false"
                    allow-revert="false"
                    instant-upload="false"
                    item-insert-location="after"
                    :value.sync="printFiles"
                    v-bind:files="localPrintFiles"
                    v-on:updatefiles="handleFilePondUpdateFile"
                />
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import { mapState, mapMutations } from 'vuex';
import axios from 'axios';
import { singleUpload, deleteObjectByKey } from '@/common/aws';
// Import Vue FilePond
import vueFilePond from 'vue-filepond';

import { logger } from '@/common/logger';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';

import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';

// Create component
const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginFileValidateSize);

export default {
    name: 'UploadChoice',
    components: {
        FilePond,
    },
    props: {
        source: String,
    },
    data() {
        return {
            leadId: '',
            caseNumber: '',
            product: '',
            showSubmit: false,
            savingSuccessful: false,
            myLinks: [],
            step: 1,
            checked: 0,
            uploadChoice: '',
            messageSent: '',
            localPrintFiles: []
        };
    },
    computed: mapState({
        user: (state) => state.user,
        isLoading: (state) => state.isLoading,
        printFiles: {
            get() {
                return this.$store.state.printFiles;
            },
            set(value) {
                this.setFiles(value);
            }
        }
    }),
    created() {
        this.leadId = this.$route.query.lid;
        this.caseNumber = this.$route.query.fn;
        this.product = this.$route.query.pr;
    },
    beforeMount() {
        this.localPrintFiles = this.$store.state.printFiles;
    },
    methods: {
        ...mapMutations([
            'setLoading',
            'setFiles'
        ]),
        handleFilePondUpdateFile(files) {
            // this.myFiles = files.map(files => files.file);
            // Show submit button when there are files to upload
            this.showSubmit = files.length > 0;
            this.setFiles(files.map(files => files.file));
        },
        submit() {
            // example of instance method call on pond reference
            this.$refs.pond.processFiles().then((files) => {
                // files have been processed
                for (let i = 0; i < this.myLinks.length; i++) {
                const element = this.myLinks[i];
                const pos = i + 1;

                const res = axios({
                    method: 'put',
                    data: {
                    link: element,
                    position: pos,
                    },
                    url: `${process.env.VUE_APP_API_URL}/leads/update/${this.leadId}`,
                    headers: {
                    'Content-Type': 'application/json',
                    },
                }).then((response) => {
                    logger.info(response.data);
                })
                .catch(error => {
                    logger.error('Upload Error - LeadId: {lead}; Fallnummer: {casenumber};  Datei_{pos}: {link}', this.leadId, this.caseNumber, pos, element);
                });
                }
                //if we come from main lead form, go straight to device page after upload is complete
                if (this.fwdMode == 'true') {
                    // forwarding to device page
                    this.$router.push({
                        name: 'Devices',
                        query: { lid: this.leadId, fn: this.caseNumber, fwd: this.fwdMode, pr: this.product }
                    });
                } else {
                    //Show Success Screen
                    this.$router.push( { name: "UploadSuccess" } );
                }
            });
        },
        async uploadFile(file, metadata, load, error, progress, abort) {
            const result = await singleUpload(
                file
            )
            if (result.status === 200) {
                // Handle storing it to your database here
                // Adding links to Zoho CRM
                this.myLinks.push(result.fullPath);

                load(file);
                
            } else {
                logger.error('Fehler in der FilePond Komponente - LeadId: {lead}; Fallnummer: {casenumber}', this.leadId, this.caseNumber);
                error(); // Let FilePond know the upload was unsuccessful
            }
            return {
                abort: () => {
                // This function is entered if the user has tapped the cancel button
                // Let FilePond know the request has been cancelled
                abort();
                },
            };
        },
    }
}
</script>